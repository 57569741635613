import React from "react";
import "./MissionHome.css";

const MissionHome = () => {
  return (
    <>
      <div className="mission-home">
        <div className="mission-home-content container">
          <div>
          <h1>OUR MISSION</h1>
          <p>
            Provide every individual a quiet, calm, and safe place to begin the
            journey. It is our philosophy that in order to reduce the incidence
            and prevalence of Mental Health issues and other behavioral health
            problems among adults through the provision of addiction treatment
            services, each individual must be empowered to make decisions about
            their care with the expected outcome of an increased quality of
            life.
          </p> <br/>
          <p><i>"It's okay not to be okay, but it's not okay to stay that way." — Unknown</i></p>
          </div>
        </div>
      </div>
    </>
  );
};

export default MissionHome;
