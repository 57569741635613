import React from "react";
import "./Section3.css";

import { Link } from "react-router-dom";
import { Link as LinkRoll } from "react-scroll";

import Card1 from "../../assets/program_php.webp";
import Card2 from "../../assets/program_iop.webp";
import Card3 from "../../assets/program_op.webp";
import Card4 from "../../assets/program_case_management.webp";
import Card5 from "../../assets/program_crisis_intervention.webp";
import Card6 from "../../assets/program_family_therapy.webp";



const Section3 = () => {
  return (
    <>
      <div className="section3">
        <div className="section3-content container">

          <div className="section3Header container">
            <h1>OUR PROGRAMS</h1>
            <h2>Holistic Mental Health Solutions</h2>
          </div>


          <div className="section3-flex">
{/* PHP */}
              <div class="section3-flexItem">
                  <img src={Card1} alt="Mental Health" loading="lazy" />
                <div>
                  <h1>PARTIAL HOSPITALIZATION</h1>
                  <p>A Partial Hospitalization Program (PHP) is a structured mental health treatment program that provides intensive therapeutic services and support during the day while allowing patients to return home at night.</p>

                  <div>
                    <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                      <Link to="/partial-hospitalization-program">
                        <button>Learn More</button>
                      </Link>
                    </LinkRoll>
                  </div>
                </div>
              </div>

{/* IOP */}

              <div class="section3-flexItem">
                  <img src={Card2} alt="Mental Health" loading="lazy" />
                <div>
                  <h1>INTENSIVE OUTPATIENT</h1>
                  <p>An Intensive Outpatient Program (IOP) is a structured treatment program that allows individuals to receive comprehensive therapy and support while living at home and continuing with their daily responsibilities.</p>
                  <div>
                    <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                      <Link to="/intensive-outpatient-program">
                        <button>Learn More</button>
                      </Link>
                    </LinkRoll>
                  </div>
                </div>
              </div>


{/* OP */}

<div class="section3-flexItem">
                  <img src={Card3} alt="Mental Health" loading="lazy" />
                <div>
                  <h1>OUTPATIENT PROGRAM</h1>
                  <p>An outpatient program provides medical or therapeutic care to patients who visit a facility for treatment without staying overnight.</p>
                  <div>
                    <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                      <Link to="/outpatient-program">
                        <button>Learn More</button>
                      </Link>
                    </LinkRoll>
                  </div>
                </div>
              </div>


{/* CASE MANAGEMENT */}

<div class="section3-flexItem">
                  <img src={Card4} alt="Mental Health" loading="lazy" />
                <div>
                  <h1>CASE MANAGEMENT</h1>
                  <p>The Case Management program efficiently coordinates comprehensive care plans, ensuring optimal outcomes and resource utilization for clients.</p>
                  <div>
                    <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                      <Link to="/case-management">
                        <button>Learn More</button>
                      </Link>
                    </LinkRoll>
                  </div>
                </div>
              </div>



{/* CRISIS INTERVENTION */}

<div class="section3-flexItem">
                  <img src={Card5} alt="Mental Health" loading="lazy" />
                <div>
                  <h1>CRISIS INTERVENTION</h1>
                  <p>Our Crisis Intervention Program provides immediate support and resources to individuals in urgent need, ensuring their safety and well-being.</p>
                  <div>
                    <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                      <Link to="/crisis-intervention">
                        <button>Learn More</button>
                      </Link>
                    </LinkRoll>
                  </div>
                </div>
              </div>



{/* FAMILY THERAPY */}

<div class="section3-flexItem">
                  <img src={Card6} alt="Mental Health" loading="lazy" />
                <div>
                  <h1>FAMILY THERAPY PROGRAM</h1>
                  <p>Empowering families to navigate challenges and strengthen bonds through compassionate and collaborative therapy.</p>
                  <div>
                    <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                      <Link to="/family-therapy">
                        <button>Learn More</button>
                      </Link>
                    </LinkRoll>
                  </div>
                </div>
              </div>



















          </div>
        </div>
      </div>

    </>
  );
};

export default Section3;
