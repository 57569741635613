import React from "react";
import "./AboutSection.css";
import AboutUs from "../../assets/about-section-min.webp";

const AboutSection = () => {
  return (
    <>
      <div className="about-container">
        <div className="about-content container">
          <div className="about-content-left">
            <img src={AboutUs} alt="nurse" loading="lazy" />
          </div>

          <div className="about-content-right">
            <h1>Welcome to Contra Costa Rehab</h1> 
            <p>
            At Contra Costa Rehab, we are dedicated to providing compassionate and comprehensive mental health and substance abuse treatment. Our mission is to empower individuals to reclaim their lives by offering a holistic approach to recovery that addresses the mind, body, and spirit.
            </p>
            <h1>Our Mission</h1> 
            <p>
            Our mission is to support and guide individuals on their journey to recovery, helping them achieve lasting sobriety and mental well-being. We are committed to fostering a nurturing environment where every person feels valued, respected, and supported in their path to a healthier life.
            </p>
            <h1>Our Approach</h1> 
            <p>We believe in a personalized treatment approach, recognizing that each individual’s journey to recovery is unique. Our multidisciplinary team of professionals collaborates to develop tailored treatment plans that meet the specific needs of each client. Our evidence-based therapies and holistic practices are designed to address the root causes of mental health and substance abuse issues, promoting long-term recovery and resilience.</p>

          </div>
        </div>
      </div>
    </>
  );
};

export default AboutSection;
