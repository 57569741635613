import React from "react";
import { Link as LinkRoll } from "react-scroll";

import "./TherapySA.css";
import { Link } from "react-router-dom";

import Nurse from '../../assets/nurse_woman.webp'

const TherapySA = () => {
  return (
    <>
      <div className="therapy-sa">

        <div className="content container">

          <div className="therapy-sa-header">
            <div>
              <h1>Addiction Disorder We Treat</h1>
            </div>
            
       
          </div>



      <div className="therapy-sa-flex">

        <div className="therapy-sa-flexRight">
            <img src={Nurse} alt="Woman Nurse" loading="lazy"/>
        </div>
        

        <div className="therapy-sa-flexLeft">
            <div className="sa-card">
              <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                <Link to="/alcohol">
                  <div className="box-content">
                    <h1>ALCOHOL ADDICTION</h1>
                    <p>Contra Costa Rehab offers comprehensive treatment programs for alcohol addiction, combining medical detoxification, individual and group therapy, and holistic approaches to support lasting recovery. Our dedicated team provides personalized care to help individuals reclaim their lives and achieve sobriety in a compassionate and nurturing environment.</p>
                  </div>
                </Link>
              </LinkRoll>
            </div>

            <div className="sa-card">
              <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                <Link to="/prescriptions">
                  <div className="box-content">
                    {/* <img src={Prescriptions} alt="Depressed Man For Alcohol Addiction" loading="lazy"/> */}
                    <h1>PRESCRIPTIONS ADDICTION</h1>
                    <p>At Contra Costa Rehab, we provide specialized treatment for prescription addiction, addressing both the physical and psychological aspects of dependency. Our holistic approach and compassionate care empower individuals to achieve sustainable recovery and reclaim their lives.</p>
                  </div>
                </Link>
              </LinkRoll>
            </div>


            <div className="sa-card">
              <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                <Link to="/cocaine">
                  <div className="box-content">
                    {/* <img src={Cocaine} alt="Depressed Man For Alcohol Addiction" loading="lazy"/> */}
                    <h1>COCAINE ADDICTION</h1>
                    <p>We offer specialized treatment programs for individuals struggling with cocaine addiction, providing a comprehensive approach that includes medical detoxification, therapy, and holistic support to ensure lasting recovery. Our dedicated team of professionals is committed to helping clients regain control of their lives and achieve long-term sobriety.</p>
                  </div>
                </Link>
              </LinkRoll>
            </div>


            <div className="therapy-sa-btn">
                  <LinkRoll
                    activeClass="active"
                    to="top"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    <Link to="/substance-abuse">
                      <div className="therapySA-btn">
                        <button>
                          Learn More
                        </button>
                      </div>
                    </Link>
                  </LinkRoll>
            </div>

          </div>


        </div>          




          </div>
        </div>
    </>
  );
};

export default TherapySA;
