import React from 'react'
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import { Link as LinkRoll } from "react-scroll";
import { FiPhone } from "react-icons/fi";
import { TfiEmail } from "react-icons/tfi";

import Slide1 from '../../assets/hero_slider_1.webp'
import Slide2 from '../../assets/hero_slider_2.webp'
import Slide3 from '../../assets/hero_slider_3.webp'

import './Hero.css'

const Hero = () => {
    return (
        <>
<div className="slider">
	<ul>
		<li><img src={Slide1} alt="Conselling Session with a patient" loading="lazy" /></li>
		<li><img src={Slide2} alt="Conselling Session with a patient" loading="lazy" /></li>
		<li><img src={Slide3} alt="Conselling Session with a patient" loading="lazy" /></li>
	</ul>

    <div className='hero-caption'> 
        <Fade right>
            <h1>CONTRA COSTA REHAB</h1>
            <h2>Your Path to Mental Wellness Starts Here</h2>
            <hr/>
        </Fade>

        <p>Contra Costa Rehab offers compassionate and comprehensive mental health and substance abuse treatment. Our personalized approach empowers individuals to achieve lasting recovery.</p>


                <div className='heroIcons'>
                
                    <a href="tel:3237920502">       
                    <div className='heroIcon heroIconPhone moving-icon'>
                        <FiPhone />
                    </div></a>
                    <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                        <Link to='/contact'>
                    <div className='heroIcon heroIconPhone icon-container'>
                        <TfiEmail />
                    </div>
                        </Link>
                    </LinkRoll>

                </div>

    </div>



</div>

        </>
    )
}

export default Hero